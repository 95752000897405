import {
    ItemBag,
    ItemBlocks,
    ItemBox,
    ItemBoxes,
    ItemCalendar,
    ItemGift,
    ItemMoney,
    ItemStopwatch,
} from './assets/images';

import { colors } from './styles/variables';

export const POPUP_RO_ORDERED_ITEMS_CONFIG = {
    title: "Cum funcționează?",
    id: "section-how-it-works",
    itemList: [
        {
            id: 1,
            title: "PASUL 1",
            text: "În timpul cumpărăturilor online, adaugă produsele în coș și selectează PayPo ca metodă de plată.",
        },
        {
            id: 2,
            title: "PASUL 2",
            text: "Dacă nu ai mai cumpărat cu PayPo, trebuie să adaugi inițial datele tale personale, iar pentru achizițiile ulterioare, confirmi tranzacția doar cu un cod SMS.",
        },
        {
            id: 3,
            title: "PASUL 3",
            text: "PayPo plătește pentru achiziția ta online și comanda se va livra la adresa dorită de tine.",
        },
        {
            id: 4,
            title: "PASUL 4",
            text: "Verifici achizițiile, păstrezi doar ce produse dorești și ai la dispoziție până la 30 de zile pentru a plăti către PayPo. Plătești, doar produsele pe care le păstrezi.",
        }
    ],
};

export const POPUP_RO_ICON_ITEMS_CONFIG = {
    title: "Beneficii",
    id: "section-benefits",
    content: [
        {
            Icon: ItemCalendar,
            description: "Ai până la 30 de zile pentru a plăti - <strong>fără costuri suplimentare</strong>",
        },
        {
            Icon: ItemBlocks,
            description: "<strong>Verifici produsele</strong> - poți returna produsele care nu ți se potrivesc, iar la final plătești doar produsele pe care decizi să le păstrezi.",
        },
        {
            Icon: ItemBag,
            description: "Cumperi în siguranță - <strong>plătești, doar după ce primești produsele</strong>",
        },
        {
            Icon: ItemBox,
            description: "Plata cu PayPo te <strong>protejează mai bine și mai ieftin decât modalitatea de plată ramburs la livrare.</strong>",
        },
    ],
    additionalText: [
        {
            text: 'Serviciul este disponibil pentru clienții noi PayPo. Informațiile nu constituie o ofertă. Mai multe detalii pot fi găsite în Termenii si conditiile serviciului "PayPo - cumpără acum, plătește în 30 de zile" pe site-ul <a href="https://www.paypo.ro">www.paypo.ro</a>',
        },
    ],
};

export const POPUP_RO_BIG_TILES_CONFIG = {
    title: "Ai întrebări?",
    id: "section-contact",
    tileList: [
        {
            id: 1,
            bgColor: colors.mintCream,
            content: {
                title: "info@paypo.ro",
                textContent: [
                    {
                        id: 1,
                        text: "Răspundem la majoritatea întrebărilor în 48 de ore",
                    },
                ],
                cta: {
                    type: "button",
                    action: "email",
                    text: "Trimite-ne un mesaj"
                }
            },
        },
        {
            id: 2,
            bgColor: colors.palePurple,
            content: {
                title: "0312 294 746",
                textContent: [
                    {
                        id: 1,
                        text: "De luni până vineri de la 8:00 la 18:00",
                    },
                ],
                cta: {
                    type: "textButton",
                    action: "phoneCall",
                    text: "Sună-ne",
                },
            },
        },
    ],
};
